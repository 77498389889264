<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="配置类型" prop="type" >
<!--        <a-select-->
<!--          v-model="form.type"-->
<!--          style="width: 100%"-->
<!--          placeholder="请选择类型"-->
<!--          :options="scriptConfigTypeOptions"-->
<!--          @change="selectConfigTypeHandleChange"-->
<!--        ></a-select>-->
        <a-select placeholder="请选择配置类型" style="width: 100%" v-model="form.type" allowClear>
          <a-select-option v-for="(item, index) in this.customDict.TalkEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="名字" prop="name" >
        <a-input v-model="form.name" placeholder="请输入名字" />
      </a-form-model-item>
      <a-form-model-item label="logo图片" prop="logo" >
        <a-upload
          name="logo"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverAvatarUpload">
          <img
            v-if="form.logo"
            :src="form.logo"
            alt="cover"
            style="height: 102px; width: 102px; border-radius: 50%" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="微信号" prop="wechat" >
        <a-input v-model="form.wechat" placeholder="请输入微信号" />
      </a-form-model-item>
      <a-form-model-item label="是否认证" prop="isAuth">
        <a-switch v-model="form.isAuth" />{{ form.isAuth ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getScriptConfig, addScriptConfig, updateScriptConfig } from '@/api/script/scriptConfig'
import { mapGetters } from 'vuex'
import { generateFilePath, uploadObject } from '@/api/tool/alioss'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: '',
      submitLoading: false,
      formTitle: '',
      scriptConfigTypeOptions: [],
      previewVisible: false,
      previewImage: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        name: null,

        logo: null,

        wechat: null,

        isAuth: null,

        publishNum: null,

        saleNum: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '配置类型不能为空', trigger: 'change' }
        ],

        name: [
          { required: true, message: '名字不能为空', trigger: 'blur' }
        ],

        /*logo: [
          { required: true, message: 'logo图片不能为空', trigger: 'blur' }
        ],

        wechat: [
          { required: true, message: '微信号不能为空', trigger: 'blur' }
        ],

        isAuth: [
          { required: true, message: '是否认证不能为空', trigger: 'blur' }
        ]*/
      }
    }
  },
  filters: {
  },
  created () {
    this.reset()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        type: null,

        name: null,

        logo: null,

        wechat: null,

        isAuth: null,

        remark: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getScriptConfig(id).then(response => {
        this.form = response.data
        // this.form.isAuth = this.form.isAuth ? 1 : 0
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateScriptConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addScriptConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    selectConfigTypeHandleChange(value) {
      this.form.type = value
    },
    selectHandleChange(value) {
      this.form.isAuth = value
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    coverAvatarUpload(file, index) {
      console.log('获取文件对象', file.file)
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'sript/config'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'logo', res)
          _this.$message.success('上传成功')
        })
      })
    }
  }
}
</script>
